<template>
  <div>
    <loading
      :active.sync="isLoading"
      color="#f8a229"
      loader="dots"
      :width="loadingSize"
      :height="loadingSize"
      :is-full-page="loadingFullPage"
    />
    <v-card-text>
      <v-container class="row1">
        <v-row>
          <v-col cols="14" sm="4" md="2">
            <v-row dense class="mb-5">
              <router-link
                v-bind:to="'/admin/admin-bills'"
                style="text-decoration: none"
              >
                <v-btn color="secondary">
                  <v-icon color="black"> mdi-arrow-left </v-icon>
                </v-btn>
              </router-link>
            </v-row></v-col
          >
          <v-col cols="14" sm="8" md="10">
            <v-row dense justify="end" class="mb-5">
              <div>
                <v-btn color="secondary" dark @click="dialog = true">
                  <v-icon color="black"> mdi-plus-circle </v-icon>
                  <v-card-text class="black--text font-weight-bold">
                    Add New Size
                  </v-card-text>
                </v-btn>
              </div>
            </v-row>
          </v-col>
        </v-row>
      </v-container>

      <v-container>
        <!-- <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          style="margin-bottom:10px; margin-top:-35px;"
        >
        </v-text-field> -->
        <v-row>
          <v-col cols="14" sm="12" md="12">
            <v-row dense class="itemsLayout">
              <v-col lg="2" md="3" sm="3" xs="4" class="pb-3">
                <v-card height="200" width="150" @click="dialog = true">
                  <v-card-text class="text-center">
                    <v-icon
                      color="secondary"
                      style="font-size: 70px"
                      class="mt-11"
                    >
                      mdi-plus
                    </v-icon>
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col
                v-for="item in dataFiltered"
                :key="item.sizeId"
                lg="2"
                md="3"
                sm="3"
                xs="4"
              >
                <v-card height="200" width="150" class="overflow-hidden">
                  <v-img
                    v-if="!item.image"
                    class="white--text align-end"
                    gradient="rgba(248,162,41,.5), rgba(0,0,0,.2)"
                    color="red"
                    width="150"
                    height="100"
                  >
                    <v-tooltip top color="black">
                      <template v-slot:activator="{ on, attrs }">
                        <div style="margin-top: 0px; text-align: center">
                          <v-icon
                            class="text-h1"
                            color="white"
                            id="imageButton"
                            @click="addImage(item)"
                            v-bind="attrs"
                            v-on="on"
                          >
                            mdi-image-plus
                          </v-icon>
                        </div>
                      </template>
                      <span>Add Image</span>
                    </v-tooltip>

                    <v-tooltip top color="black">
                      <template v-slot:activator="{ on, attrs }">
                        <div
                          style="
                            margin-top: -76px;
                            float: left;
                            background-color: white;
                            border-radius: 0px 10px 10px 0px;
                          "
                        >
                          <v-icon
                            color="red"
                            @click="deleteItem(item)"
                            v-bind="attrs"
                            v-on="on"
                          >
                            mdi-delete-circle
                          </v-icon>
                        </div>
                      </template>
                      <span>Delete {{ item.departmentName }}</span>
                    </v-tooltip>

                    <v-tooltip top color="black">
                      <template v-slot:activator="{ on, attrs }">
                        <div
                          style="
                            float: right;
                            background-color: white;
                            border-radius: 15px 0px 0px 0px;
                          "
                        >
                          <v-icon
                            color="black"
                            @click="viewEditItem(item)"
                            v-bind="attrs"
                            v-on="on"
                          >
                            mdi-information
                          </v-icon>
                        </div>
                      </template>
                      <span>More Info</span>
                    </v-tooltip>
                  </v-img>
                  <v-img
                    v-if="item.image"
                    :src="$url + item.image"
                    class="white--text align-end"
                    width="150"
                    height="150"
                    style=""
                  >
                    <v-tooltip top color="black">
                      <template v-slot:activator="{ on, attrs }">
                        <div
                          style="
                            margin-top: -126px;
                            float: left;
                            background-color: white;
                            border-radius: 0px 10px 10px 0px;
                          "
                        >
                          <v-icon
                            color="red"
                            @click="deleteItem(item)"
                            v-bind="attrs"
                            v-on="on"
                          >
                            mdi-delete-circle
                          </v-icon>
                        </div>
                      </template>
                      <span>Delete {{ item.sizeName }}</span>
                    </v-tooltip>

                    <v-tooltip top color="black">
                      <template v-slot:activator="{ on, attrs }">
                        <div
                          style="
                            float: right;
                            background-color: white;
                            border-radius: 15px 0px 0px 0px;
                          "
                        >
                          <v-icon
                            color="black"
                            @click="viewEditItem(item)"
                            v-bind="attrs"
                            v-on="on"
                          >
                            mdi-information
                          </v-icon>
                        </div>
                      </template>
                      <span>More Info</span>
                    </v-tooltip>
                  </v-img>

                  <v-card-text
                    @click="viewEditItem(item)"
                    style="cursor: pointer"
                    class="black--text"
                  >
                    <div class="headerClass">
                      <v-icon style="font-size: 20px" class="mb-1">
                        mdi-shape-circle-plus
                      </v-icon>

                      {{ item.sizeName }}
                    </div>

                    <div>
                      <v-chip
                        label
                        color="green"
                        class="white--text"
                        style="height: 25px"
                        v-if="item.isInactive === true"
                      >
                        Active
                      </v-chip>
                      <v-chip
                        label
                        color="red"
                        class="white--text"
                        style="height: 25px"
                        v-if="item.isInactive === false"
                      >
                        In Active
                      </v-chip>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-col cols="14" sm="12" md="12">
              <v-pagination
                v-model="page"
                :length="pageCount"
                @click="changePage"
                circle
                color="black"
              ></v-pagination>
            </v-col>
          </v-col>
        </v-row>
        <v-col cols="14" sm="8" md="4" class="float-right mt-n15">
          <v-row dense class="mb-5 float-right">
            <v-tooltip left color="black">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="secondary"
                  @click="dialogTrash = true"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon color="black"> mdi-delete-restore </v-icon>
                </v-btn>
              </template>
              <span>Trash</span>
            </v-tooltip>
          </v-row></v-col
        >
      </v-container>

      <v-dialog v-model="dialogViewItem" max-width="500px" origin="top right">
        <v-card>
          <v-row style="float: right; margin-top: 10px; margin-right: 10px">
            <div>
              <v-icon color="closeButton" @click="dialogViewItem = false">
                mdi-close-circle
              </v-icon>
            </div>
          </v-row>
          <v-card-title style="font-size: 20px">
            Size Info
            <v-tooltip right color="black">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="secondary"
                  @click="editItem()"
                  style="margin-left: 10px"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-circle-edit-outline
                </v-icon>
              </template>
              <span>Edit</span>
            </v-tooltip>
          </v-card-title>

          <v-card-title
            style="
              font-size: 18px;
              font-weight: bold;
              text-transform: uppercase;
            "
          >
            {{ viewItem.sizeName }}
          </v-card-title>
          <v-divider class="mx-4"></v-divider>

          <v-card-text>
            Note :
            {{ viewItem.note }}
          </v-card-text>

          <v-card-text>
            <v-chip
              label
              color="green"
              class="white--text"
              v-if="viewItem.isInactive === true"
            >
              Active
            </v-chip>
            <v-chip
              label
              color="red"
              class="white--text"
              v-if="viewItem.isInactive === false"
            >
              In Active
            </v-chip>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialog" max-width="500px" origin="top right">
        <v-card>
          <v-row style="float: right; margin-top: 10px; margin-right: 10px">
            <div>
              <v-icon color="closeButton" @click="close">
                mdi-close-circle
              </v-icon>
            </div>
          </v-row>
          <v-card-title>
            <span class="headline">{{ formTitle }}</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-form ref="editedForm">
                <v-row>
                  <v-col cols="12" class="mb-n8">
                    <v-text-field
                      v-model="editedItem.sizeName"
                      label="Size Name"
                      outlined
                      clearable
                    />
                  </v-col>
                  <v-col v-if="titleChange == -1" cols="12" class="mb-n8">
                    <v-file-input
                      v-model="editedItem.formFile"
                      accept="image/png, image/jpeg, image/bmp"
                      prepend-icon="mdi-camera"
                      label="Image"
                      outlined
                      clearable
                    />
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer />

            <v-btn color="blue darken-1" text @click="close"> Cancel </v-btn>
            <v-btn color="blue darken-1" text @click="validate"> Save </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogTrash" max-width="1200">
        <v-card class="pa-10">
          <v-row style="float: right">
            <div>
              <v-icon color="closeButton" @click="dialogTrash = false">
                mdi-close-circle
              </v-icon>
            </div>
          </v-row>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          >
          </v-text-field>

          <v-data-table
            :headers="headers"
            :items="trashed"
            :items-per-page="5"
            :search="search"
          >
            <template v-slot:[`item.isInactive`]="{ item }">
              <div>
                <v-chip
                  label
                  style="color: green; width: 50%"
                  v-if="item.isInactive === true"
                >
                  Active
                </v-chip>
                <v-chip
                  label
                  style="color: red; width: 50%"
                  v-if="item.isInactive === false"
                >
                  In Active
                </v-chip>
              </div>
            </template>

            <template v-slot:[`item.restore`]="{ item }">
              <v-icon color="secondary" @click="restoreItem(item)">
                mdi-restore
              </v-icon>
            </template>

            <template v-slot:[`item.delete`]="{ item }">
              <v-icon color="#FF0000" @click="permanentDelete(item)">
                mdi-trash-can-outline
              </v-icon>
            </template>
          </v-data-table>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogRestore" max-width="500px">
        <v-card>
          <v-row style="float: right; margin-top: 10px; margin-right: 10px">
            <div>
              <v-icon color="closeButton" @click="closeRestore">
                mdi-close-circle
              </v-icon>
            </div>
          </v-row>
          <v-card-title class="headline">
            Are you sure you want to restore
            {{ editedItem.sizeName }} ?
          </v-card-title>
          <v-card-actions>
            <v-spacer />
            <v-btn color="blue darken-1" text @click="closeRestore">
              Cancel
            </v-btn>
            <v-btn color="blue darken-1" text @click="restoreItemConfm">
              OK
            </v-btn>
            <v-spacer />
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
          <v-row style="float: right; margin-top: 10px; margin-right: 10px">
            <div>
              <v-icon color="closeButton" @click="closeDelete">
                mdi-close-circle
              </v-icon>
            </div>
          </v-row>
          <v-card-title class="headline">
            Are you sure you want to delete {{ editedItem.sizeName }}?
          </v-card-title>
          <v-card-actions>
            <v-spacer />
            <v-btn color="blue darken-1" text @click="closeDelete">
              Cancel
            </v-btn>
            <v-btn color="blue darken-1" text @click="trashItem"> OK </v-btn>
            <v-spacer />
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogPermanentDelete" max-width="500px">
        <v-card>
          <v-row style="float: right; margin-top: 10px; margin-right: 10px">
            <div>
              <v-icon color="closeButton" @click="closeDelete">
                mdi-close-circle
              </v-icon>
            </div>
          </v-row>
          <v-card-title class="headline">
            Are you sure you want to delete
            {{ editedItem.sizeName }} permanently?
          </v-card-title>
          <v-card-actions>
            <v-spacer />
            <v-btn color="blue darken-1" text @click="closeDelete">
              Cancel
            </v-btn>
            <v-btn color="blue darken-1" text @click="deleteItemConfirm">
              OK
            </v-btn>
            <v-spacer />
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-text>
  </div>
</template>

<style>
.headerClass {
  white-space: nowrap;
  word-break: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  color: black;
  font-weight: bold;
}
.row1 {
  margin-top: -25px;
}
.itemsLayout {
  height: 415px;
  overflow: hidden;
  overflow-y: auto;
}
.addbutton {
  float: right;
}
.temp {
  background-color: rgb(255, 255, 255);
}
</style>
<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import MaterialCard from "../components/MaterialCard.vue";

export default {
  components: { MaterialCard, Loading },
  data() {
    return {
      isLoading: false,
      loadingSize: 50,
      loadingFullPage: true,
      loading: false,
      dialogViewItem: false,
      viewItem: [],
      dialogTrash: false,
      dialog: false,
      dialogDelete: false,
      dialogPermanentDelete: false,
      dialogRestore: false,
      dateModal: false,
      search: "",

      headers: [
        { text: "Size Id", value: "sizeId" },
        { text: "Size", value: "sizeName" },

        { text: "", value: "restore" },
        { text: "", value: "delete" },
      ],

      status: [
        { text: "Acitve ", value: true },
        { text: "InActive ", value: false },
      ],
      titleChange: -1,
      editedIndex: "",
      editedItem: {
        sizeName: "",
        formFile: null,
        isInactive: true,
      },
      defaultItem: {
        sizeName: "",
        formFile: null,
        isInactive: true,
      },
      trashed: [],
      page: 1,
      pageCount: null,
      start: 0,
      end: 11,
      dataFiltered: [],

      filtered: {
        orderBy: "",
        orderDirection: "desc",
        skip: 0,
        take: 11,
        searchValue: "",
        searchColumn: "",
        clubId: "",
      },
      formData: null,

      dialogImage: false,
      editedImage: {
        departmentId: "",
        formFile: "",
      },
    };
  },

  computed: {
    formTitle() {
      return this.titleChange === -1 ? "Add New Size" : "Edit Size";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    dialogPermanentDelete(val) {
      val || this.closeDelete();
    },
    dialogRestore(val) {
      val || this.closeRestore();
    },
    page: function () {
      this.changePage();
    },
    // dialogViewItem() {
    //   val || this.viewItem;
    // }
  },

  created() {
    this.getDataFromApi();

    //  this.getFilteredData();
  },

  methods: {
    getDataFromApi() {
      this.isLoading = true;
      this.$http
        .get("/Size")
        .then((response) => {
          this.sizes = response.data.data;
          this.sizes = this.sizes.reverse();
          this.$http.get("/Size/GetTrashed").then((response) => {
            this.trashed = response.data.data;
          });
          this.changePage();
        })
        .catch((error) => {
          console.log("Error");
          if (error.response.status) {
            this.$toast.error("Error in getting Data", "Error", {
              position: "topRight",
            });
            this.isLoading = false;
          }
        });
    },
    // getFilteredData() {
    //   this.$http
    //     .post("/Size/GetAllByFilter", this.filtered)
    //     .then(response => {
    //       this.dataFiltered = response.data.data;
    //     });
    // },
    updateData() {
      this.page = 1;
      this.changePage();
    },
    changePage() {
      // var diff = this.page - 1;
      // this.filtered.skip = this.start + this.end * diff;
      // this.getFilteredData();

      var count = this.sizes.length;
      var float = count / this.end;
      var int = parseInt(count / this.end);
      if (float > int) {
        this.pageCount = int + 1;
      } else {
        this.pageCount = int;
      }

      var diff = this.page - 1;
      var newStart = this.start + this.end * diff;
      var newEnd = this.end + this.end * diff;

      const items = this.sizes.slice(newStart, newEnd);

      this.dataFiltered = items;
      this.isLoading = false;
    },
    viewEditItem(item) {
      this.editedIndex = this.dataFiltered.indexOf(item);
      this.viewItem = Object.assign({}, item);
      this.dialogViewItem = true;
    },
    editItem() {
      this.editedItem = Object.assign({}, this.viewItem);
      this.titleChange = 0;
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.dataFiltered.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    restoreItem(item) {
      this.editedIndex = this.trashed.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogRestore = true;
    },
    permanentDelete(item) {
      this.editedIndex = this.trashed.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogPermanentDelete = true;
    },

    trashItem() {
      console.log(this.editedItem);
      this.$http
        .post("/Size/Trash", this.editedItem)
        .then((response) => {
          if (response.data.success === true) {
            this.$toast.success("Deleted successfully.", "Success", {
              position: "topRight",
            });
            this.sizes.splice(this.editedIndex, 1);
            this.trashed.unshift(this.editedItem);
            this.updateData();
            this.closeDelete();
          } else {
            this.$toast.error(response.data.message, "Error", {
              position: "topRight",
            });
          }
        })
        .catch((error) => {
          if (error.response.status) {
            this.$toast.error(" Deleting Process Failed", "Error", {
              position: "topRight",
            });
          }
        });
    },

    restoreItemConfm() {
      this.$http
        .post("/Size/Restore", this.editedItem)
        .then((response) => {
          if (response.data.success === true) {
            this.trashed.splice(this.editedIndex, 1);

            this.sizes.unshift(response.data.data);

            this.updateData();
            this.$toast.success("Restore successfully.", "Success", {
              position: "topRight",
            });
          } else {
            this.$toast.error(response.data.message, "Error", {
              position: "topRight",
            });
          }
        })
        .catch((error) => {
          if (error.response.status) {
            this.$toast.error(" Restore Process Failed", "Error", {
              position: "topRight",
            });
          }
        });

      this.dialogRestore = false;
    },
    deleteItemConfirm() {
      var itemId = this.editedItem.sizeId;
      console.log(itemId);
      console.log("1");
      this.$http
        .delete("/Size/" + itemId)
        .then((response) => {
          if (response.data.success === true) {
            this.$toast.success("Deleted Permanently.", "Success", {
              position: "topRight",
            });
            this.trashed.splice(this.editedIndex, 1);
          }
        })

        .catch((error) => {
          if (error.response.status === 404) {
            this.$toast.error(response.data.message, "Error", {
              position: "topRight",
            });
          }
        });

      console.log("3");
      this.closeDelete();
    },
    setDefault() {
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.titleChange = -1;
      });
    },
    close() {
      this.setDefault();
      this.dialog = false;
    },

    closeDelete() {
      this.setDefault();
      this.dialogDelete = false;
      this.dialogPermanentDelete = false;
    },

    closeRestore() {
      this.setDefault();
      this.dialogRestore = false;
    },

    validate() {
      if (this.editedItem.sizeName) {
        this.createFormData();
      } else {
        this.$toast.error("Please Fill the Form", "Error", {
          position: "topRight",
        });
      }
    },
    createFormData() {
      this.formData = new FormData();

      this.formData.append("sizeName", this.editedItem.sizeName);

      this.formData.append("isInactive", this.editedItem.isInactive);
      this.formData.append("formFile", this.editedItem.formFile);

      for (var pair of this.formData.entries()) {
        console.log(pair[0] + ":" + pair[1]);
      }
      this.save();
    },

    save() {
      if (this.titleChange === -1) {
        this.$http
          .post("/Size", this.formData)
          .then((response) => {
            if (response.data.success === true) {
              console.log(response);
              this.sizes.unshift(response.data.data);
              this.updateData();
              this.$toast.success("Size added successfully.", "Success", {
                position: "topRight",
              });
            } else {
              this.$toast.error(response.data.message, "Error", {
                position: "topRight",
              });
            }
          })
          .catch((error) => {
            if (error.response.status) {
              this.$toast.error("Adding Process Failed", "Error", {
                position: "topRight",
              });
            }
          });
      } else {
        var itemId = this.editedItem.sizeId;
        this.$http
          .put("/Size/" + itemId, this.editedItem)
          .then((response) => {
            if (response.data.success === true) {
              this.viewItem = Object.assign({}, response.data.data);
              this.sizes.splice(this.editedIndex, 1);
              this.sizes.unshift(response.data.data);
              this.updateData();
              this.$toast.success("Size Edited successfully.", "Success", {
                position: "topRight",
              });
            } else {
              this.$toast.error(response.data.message, "Error", {
                position: "topRight",
              });
            }
          })
          .catch((error) => {
            if (error.response.status) {
              this.$toast.error("Editing Process Failed", "Error", {
                position: "topRight",
              });
            }
          });
      }
      this.close();
    },
    getStatusColor(data) {
      switch (data) {
        case "true":
          return "success";
        case "false":
          return "error";
        default:
          return "";
      }
    },
  },
};
</script>
